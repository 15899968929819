<template>
  <div class="Home">
    <div class="center">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="300">
        <van-swipe-item v-for="(i,index) in swipeList.arr" :key="index">
          <img :src="'https://wyxc.047x.com'+ i.image"/>
        </van-swipe-item>
      </van-swipe>
      <div class="switch">
        <div class="right">
          <div class="open" @click="switchDoor(1)">
            <img src="./images/openDoor.png"/>
            <div>
              <p class="title">开门</p>
              <p class="text">点我开门</p>
            </div>
          </div>
          <div class="close" @click="switchDoor(2)">
            <img src="./images/closeDoor.png"/>
            <div>
              <p class="title">关门</p>
              <p class="text">点我关门</p>
            </div>
          </div>
        </div>
      </div>
      <div class="inProgress" v-if="isShow" @click="CarWashDetails">
				<div class="inProgressContent">
					<div class="inHeader">
						{{ shopInfo.shop_name }}
					</div>
					<div class="inContent">
						<span>
							<span>{{ shopInfo.name }}</span>
							<span>工位号</span>
						</span>
						<span>
							<span>{{ useTimes }}</span>
							<span>使用时长</span>
						</span>
						<span>
							<span>{{ card }}</span>
							<span>剩余次数</span>
						</span>
					</div>
				</div>
				<div class="inProgressBtn">
					洗车中
				</div>
			</div>
    </div>
    <Tabbar class="Tabbar" :value="0"/>
  </div>
</template>

<script setup>
  import { ref,onMounted,reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { reqGetParkingInfo,reqGetUserOrder,reqGetShopUserCard,reqSwitchDoor,reqAdpicture } from '../../API/home'
  import { showToast } from 'vant'

  const isShow = ref(false)
  const $router = useRouter()
  const useTimes = ref()
  const shopInfo = reactive({})     //店铺信息
  const card = ref()
  const swipeList = reactive({
    arr:[]
  })

  onMounted(() => {
    getIndexImg()
    getParkingInfo()
  })

  //获取顶部轮播图
  const getIndexImg = async () => {
    const result = await reqAdpicture({type:'首页顶部广告'})
    if(result.code == 1) swipeList.arr = result.data
  }

  //开关门
  const switchDoor = async (type) => {
    const result = await reqSwitchDoor({
      parking_id:localStorage.getItem('parking_id'),
      shop:shopInfo.shop_id,
      type
    })
    showToast(result.msg)
    //console.log(result);
  }

  //获取店铺信息
  const getParkingInfo = async () => {
    const result = await reqGetParkingInfo({
      parking_id:localStorage.getItem('parking_id')
    })
    //console.log(result);
    if(result.code == 1){
      Object.assign(shopInfo,result.data.info)
      getUserOrder()
      getShopUserCard()
    }
  }

  //获取洗车状态
  const getUserOrder = async () => {
    let timer
    clearInterval(timer)
    const result = await reqGetUserOrder({
      parking_id:localStorage.getItem('parking_id'),
      shop_id:shopInfo.shop_id,
      id:localStorage.getItem('thisCardId')
    })
    //console.log(result);
    if(result.code == 1){
      isShow.value = true
      timer = setInterval(function() {
        useTimes.value = getTime(result.data.create_time)
      }, 1000);
    }else{
      isShow.value = false
    }
  }

  //获取店铺账户信息
  const getShopUserCard = async () => {
    const result = await reqGetShopUserCard({
      shop_id:shopInfo.shop_id
    })
    if(result.code == 1) card.value = result.data.surplus_many
  }

  //获取使用时间
  const getTime = (time) => {
    var times = parseInt((new Date()) / 1000);
    times = times - time;
    var days = Math.floor(times / 3600 / 24);
    var hours = Math.floor((times - days * 24 * 3600) / 3600);
    var mins = Math.floor((times - days * 24 * 3600 - hours * 3600) / 60);
    var secs = Math.floor((times - days * 24 * 3600 - hours * 3600 - mins * 60));
    if(hours < 10){
      hours = '0'+ hours;
    }
    if(mins < 10){
      mins = '0'+ mins;
    }
    if(secs < 10){	
      secs = '0'+ secs;
    }
    return hours + ':' + mins + ':' + secs;
  }
  
  const CarWashDetails = () => {
    $router.push({
      path:'/',
      query:{
        parking_id:localStorage.getItem('parking_id')
      }
    })
  }

</script>

<style lang="scss" scoped>
  .Home{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f7f7f7;
    .center{
      width: 100%;
      height: calc(100% - 50px);
      overflow: auto;
      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .switch{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        .immediately{
          flex: 0 0 45.5%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          height: 160px;
          border-radius: 10px;
          margin-left: 3%;
          .text{
            margin: 10px 0;
          }
          img{
            width: 80px;
            height: 70px;
          }
        }
        .right{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex: 0 0 90%;
          height: 160px;
          border-radius: 10px;
          margin: 0 auto;
          .open{
            width: 100%;
            display: flex;
            flex: 0 0 46%;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            img{
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }
          .close{
            width: 100%;
            flex: 0 0 46%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            img{
              width: 34px;
              height: 40px;
              margin-right: 10px;
            }
          }
        }
        .text{
          color: #999999;
        }
        .title{
          color: #fa2e23;
          font-weight: bold;
        }
      }
      .inProgress{
				position: relative;
				width: 92%;
				margin: 50px auto 0 auto;
				border-radius: 15px;
				.inProgressContent{
					width: 100%;
          height: 90px;
					background-color: #fa3d33;
					padding: 15rpx 15rpx 10rpx 15rpx;
					color: white;
					border-top-left-radius: 15px;
					border-top-right-radius: 15px;
					.inHeader{
						text-align: center;
						font-size: 20px;
						padding: 10px 0;
					}
					.inContent{
						display: flex;
						justify-content: space-around;
						span{
							display: flex;
							flex: 1;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							font-size: 16px;
							span{
								&:nth-child(1){
									margin-bottom: 10px;
									font-size: 16px;
								}
							}
						}
					}
				}
				.inProgressBtn{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding: 10px 0;
					border-bottom-left-radius: 15px;
					border-bottom-right-radius: 15px;
					font-size: 25rpx;
					background-color: #deeeee;
					color: #fa3d33;
				}
			}
    }
    .Tabbar{
      position: sticky;
    }
  }
</style>