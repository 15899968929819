import request from "../utils/request";

const API = {
  GET_PARKING_INFO_URL:'/api/v2/shop/get_parking_info',         //获取车位信息
  GET_USER_ORDER_URL:'/api/v2/user/getUserOrder',               //获取工位状态 
  GET_SHOP_USER_CARD_URL:'/api/v2/user_center/my_one_shop_card_info',//获取店铺账户信息     
  START_CAT_WASH_URL:'/api/v2/user/user_scan_open_door',        //开始洗车 
  END_CAT_WASH_URL:'/api/v2/user/user_settlement_opening',      //结束洗车  
  SWITCH_DOOR_URL:'/api/v2/user/switchDoor',                    //开关门    
  ADPICTURE_URL:'/api/v2/index/adpicture',                      //首页顶部广告   
}

export const reqGetParkingInfo = (data) => request.post(API.GET_PARKING_INFO_URL,data)
export const reqGetUserOrder = (data) => request.post(API.GET_USER_ORDER_URL,data)
export const reqGetShopUserCard = (data) => request.post(API.GET_SHOP_USER_CARD_URL,data)
export const reqStartCatWash = (data) => request.post(API.START_CAT_WASH_URL,data)
export const reqEndCatWash = (data) => request.post(API.END_CAT_WASH_URL,data)
export const reqSwitchDoor = (data) => request.post(API.SWITCH_DOOR_URL,data)
export const reqAdpicture = (data) => request.post(API.ADPICTURE_URL,data)